<template>
  <div>
    <!-- Start Header Area -->
    <!-- dd -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image about-top"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title" style="color:#fff;">Overview</h2>
              <p>
                  (주)데이터엔지니어스랩과 함께해요.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start About Area  -->
    <div class="about-area ptb--120 bg_color--1">
      <div class="about-wrapper">
        <About>
          <img
            slot="thum-img"
            class="w-100"
            src="../../assets/images/about/about-1.png"
            alt="About Images"
          />
        </About>
      </div>
    </div>
    <!-- Start About Area  -->

    <!-- Start Counterup Area  -->
    <div class="rn-counterup-area ptb--120 bg_color--5">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center section-title">
              <!-- <span class="subtitle">개요</span> -->
              <h2 class="heading-title">About Us</h2>
              <p class="description">
                (주)데이터엔지니어스랩은 데이터에 대한 노하우로 모든 데이터 고민을 해결합니다.<br />
                데이터 전문가들과 상의해보세요.
              </p>
              <p class="description"></p>
            </div>
          </v-col>


          <v-col lg="6" md="12" cols="12 mt--30 mb--30">
            <div class="thumb position-relative">
              <div class="thumbnail position-relative">
                <img class="w-100" src="../../assets/images/about/ml_service_.png" alt="" />
              </div>
            </div>
          </v-col>
          
          <v-col lg="6" md="12" cols="12">
            <div class="details mt_md--30">
              <div class="section-title">
                <h4 class="heading-title">비즈니스 분석</h4>
                <p class="description" align="justify">프로젝트를 시작할 때, 제품 요구 사항이나 비즈니스 도전 과제를 연구하고, 솔루션을 연결하여 데이터와 가치를 창출하기 위한 비전을 문서화합니다. 팀 전체가 공유하고 이해할 수 있는 기반을 마련하며, 프로젝트의 목표와 방향성을 설정하는 데 핵심적인 역할을 합니다. </p>
                <h4 class="heading-title">데이터 탐색적 분석</h4>
                <p class="description" align="justify">현재 데이터 인프라를 면밀히 검토하고, 데이터 집합 내의 이상 현상, 누락된 값, 의존성 및 패턴을 발견하기 위해 광범위한 조사를 진행합니다. 데이터의 질을 평가하고, 후속 분석 및 모델링 단계에서의 정확도와 효율성을 높이기 위한 중요한 인사이트를 도출합니다.</p>
                <h4 class="heading-title">데이터 전처리</h4>
                <p class="description" align="justify">데이터를 정제하고 일관된 통합 형식으로 변환하는 과정을 통해 데이터를 철저히 준비합니다. 이는 데이터의 품질을 보장하고, 분석 및 모델링의 정확도를 높이기 위한 필수적인 단계로, 분석 결과의 신뢰성을 극대화하는 데 중추적인 역할을 합니다.</p>
                <h4 class="heading-title">데이터 모델링 및 평가</h4>
                <p class="description" align="justify">다양한 모델을 훈련시켜 가장 정확한 결과를 제공하는 모델을 선정합니다. 그 후, 정확도, 단순성, 그리고 성능 측면에서 최상의 모델을 선택하며, 이 과정에서 모델의 일반화 능력을 확인하기 위해 교차 검증과 같은 기법을 활용하여 모델의 신뢰성을 추가로 평가합니다.</p>
                <h4 class="heading-title">솔루션 설계</h4>
                <p class="description" align="justify">BI제품, 머신러닝 또는 데이터 관리 솔루션 등의 프로젝트 유형에 관계없이, 최신 기술 동향과 혁신적 기능을 수용하며 제품을 체계적으로 설계, 통합, 그리고 테스트합니다. 업계 표준에 부합하는 아키텍처 구축과 함께, 요구 사항을 충족시키는 견고하고 확장 가능한 솔루션을 제공하기 위해 노력합니다.</p>
                <h4 class="heading-title">지원 및 유지보수</h4>
                <p class="description" align="justify">우리는 고객의 성장을 지원하며, 지속적인 피드백 수집과 성능 모니터링을 통해 제품의 안정성을 보장하고 고객의 변화하는 요구사항에 신속하게 대응할 수 있는 유연성을 제공합니다.</p>
              </div>
            </div>
          </v-col>



        </v-row>
      </v-container>
    </div>
    <!-- End Counterup Area  -->

    <!-- Start Counterup Area  -->
    <div class="rn-counterup-area ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center section-title">
              <!-- <span class="subtitle">조직도</span> -->
              <h2 class="heading-title">Organization</h2>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <!-- <v-col cols="2"></v-col> -->
          <v-col cols="12"><img class="w-100" src="../../assets/images/about/organization.png" alt="" /></v-col>
          <!-- <v-col cols="2"></v-col> -->

        </v-row>
      </v-container>
    </div>
    <!-- End Counterup Area  -->

    <!-- Start Finding us Area  -->
    <div
      class="rn-finding-us-area attacment-fixed rn-finding-us ptb--80 data-bg"
      data-black-overlay="1"
    >
      <v-container>
        <v-row>
          <v-col lg="8" offset-lg="2">
            <div class="inner">
              <div class="content-wrapper">
                <div class="text-center content">
                  <img src="../../assets/images/logo/intelligence.png" alt="" />
                  <p>
                   (주)데이터엔지니어스랩의 비전은 데이터에 대한 지식이 많지 않더라도 모두가 쉽게 사용하고 응용할 수 있는 플랫폼을 지향합니다. 
                  </p>
                  <a href="https://dlab.kr/pdf/DLab_Intro_v3_2024.pdf" target="_blank"><button class="btn-default">회사소개서 다운로드</button></a>
                  <!-- <a href="../../assets/pdf/DLab_Intro_v3_2024.pdf" target="_blank"><button class="btn-default">회사소개서 다운로드</button></a> -->
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Finding us Area  -->

    
    
    
    <Footer />
  </div>
</template>

<script>
  import Header from "../../components/header/Header";
  import About from "../../components/about/About";
  import CounterOne from "../../components/counter/CounterOne";
  import TeamThree from "../../components/team/TeamThree";
  import Testimonial from "../../components/testimonial/Testimonial";
  import Brand from "../../components/brand/Brand";
  import Footer from "../../components/footer/Footer";
  export default {
    components: {
      Header,
      About,
      CounterOne,
      TeamThree,
      Testimonial,
      Brand,
      Footer,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "About",
            to: "",
            disabled: true,
          },
        ],
      };
    },
  };
</script>
